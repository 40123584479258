import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import * as styles from "./drawing.module.scss";

export default function Drawing({data}) {
    const image = getImage(data.contentfulApDrawing.picture)
    return (
        <Layout>
            <div className={styles.container}>
                <h1>{data.contentfulApDrawing.title}</h1>
                <GatsbyImage alt={data.contentfulApDrawing.title} image={image}/>
            </div>
        </Layout>
    )
}

export const query = graphql`
query ($slug: String!) {
  contentfulApDrawing(slug: {eq: $slug}) {
    title
    comments {
        comments
    }
    picture {
        gatsbyImageData
    }
  }
}
`